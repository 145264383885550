<template>
  <div>
    <load-profile></load-profile>

    <n-search-container
      :show-header="true"
      :defaultVisible="true"
      @search="search"
      @reset="reset"
      :fields="searchFields"
    >
      <n-search-input ref="search" :fields="searchFields" v-model="params">
      </n-search-input>
    </n-search-container>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-0-5">
        <b-row>
          <b-col class="text-left">
            <b-button @click="batchCopyForApi" variant="primary"
              >Copy For API</b-button
            >
          </b-col>
          <b-col class="text-right">
            <b-button
              :disabled="reTopUpLoading"
              @click="reTopUpBatch"
              variant="primary"
              >{{ $t('button.retopup') }}</b-button
            >
          </b-col>
        </b-row>
      </div>
      <div>
        <n-table
          @sort-changed="sortChanged"
          :fields="fields"
          :items="items"
          :busy="loading"
          :current-page="params.page"
          :per-page="$store.state.pagination.perPage"
          :total="total"
        >
          <template #cell(paymentSuccess)="data">
            <b-badge
              pill
              variant="danger"
              badge-glow
              v-if="data.item.paymentSuccess === 0"
            >
              Failed
            </b-badge>
            <b-badge
              pill
              variant="success"
              badge-glow
              v-else-if="data.item.paymentSuccess === 1"
            >
              Success
            </b-badge>
          </template>
        </n-table>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BAvatar,
  BBadge,
  BCollapse,
  BMedia,
  BMediaAside,
  BCardText,
  BCardBody,
  BCardHeader,
  BMediaBody,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Repository from '@/repositories/RepositoryFactory'
import NPagination from '@/components/NPagination'
import NSearchContainer from '@/components/NSearchContainer'
import NSearchInput from '@/components/NSearchInput'
import NButtonDelete from '@/components/NButtonDelete'
import NTable from '@/components/NTable'
import tableFields from './tableField'
import searchInputs from './searchInput'
import { v4 as uuidv4 } from 'uuid'
import axios from 'axios'
import { useClipboard } from '@vueuse/core'

const PartnerTransactionRepository = Repository.get('partnerTransaction')

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BAvatar,
    NPagination,
    NSearchContainer,
    NSearchInput,
    NButtonDelete,
    NTable,
    vSelect,
    BBadge,
    BCollapse,
    BMedia,
    BMediaAside,
    BCardText,
    BCardBody,
    BCardHeader,
    BMediaBody,
  },
  watch: {
    perPage(value) {
      this.list(1)
    },
  },
  computed: {
    accordion() {
      return this.$parent.accordion
        ? `accordion-${this.$parent.collapseID}`
        : null
    },
    perPage: {
      get() {
        return this.$store.state.pagination.perPage
      },
      set(value) {
        this.$store.commit('pagination/SET_PER_PAGE', value)
      },
    },
    activeSearch() {
      return !!this.$route.query.name || !!this.$route.query.isEnable
    },
    fields() {
      let fieldKeys = tableFields.map((item) => {
        if (item.key == 'name') {
          item.sortField = `name_${this.$i18n.locale}`
        }
        return item
      })
      return fieldKeys
    },
  },

  data() {
    this.searchFields = this.searchFields.map((field) => {
      if (field.key == 'sortPayment') {
        field.options = [
          { id: 'desc', name: this.$t('field.mostPay') },
          { id: 'asc', name: this.$t('field.leastPay') },
        ]
      }
      if (field.key == 'sortType') {
        field.options = [
          { id: 'manual', name: this.$t('field.manual') },
          { id: 'instant', name: this.$t('field.instant') },
          { id: 'pre_order', name: this.$t('field.preOrder') },
        ]
      }
      return field
    })
    return {
      items: [],
      loading: false,
      reTopUpLoading: false,
      total: 0,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      params: {
        page: Number(this.$route.query.page) || 1,
        order: this.$route.query.order || null,
        sort: this.$route.query.sort || null,
        name: this.$route.query.name || null,
        isEnable: this.$route.query.isEnable || null,
        limit: this.$store.state.pagination.perPage || 10,
        sortPay: null,
        sortType: null,
        type: null,
      },
      visible: false,
      collapseItemID: '',
      openOnHover: this.$parent.hover,
      loadingReport: false,
      report: {
        preOrderSum: 0,
        manualSum: 0,
        instantSum: 0,
      },
    }
  },
  mounted() {
    this.getData(this.params.page)
  },
  methods: {
    updateQuerySting() {
      this.$router.replace({ query: { tran_id: this.params.tran_id } })
    },
    list(page = 1) {
      this.params.page = page
      this.updateQuerySting()
      this.getData()
    },
    sortChanged(sortBy, sortDirection) {
      this.params.order = sortBy
      this.params.sort = sortDirection
      this.updateQuerySting()
      this.getData()
    },
    search(searchText) {
      this.params.search = searchText
      this.updateQuerySting()
      this.getData()
    },
    reset() {
      this.params.search = ''
      this.params.sortBy = null
      this.params.type = null
      this.$refs.search.reset()
      this.updateQuerySting()
      this.getData()
    },
    spliceItem(id) {
      const deleteIndex = this.items.findIndex((item) => {
        return item.id === id
      })

      this.items.splice(deleteIndex, 1)
      this.total -= 1
    },
    async processChunks(chunks, limit) {
      const requests = []
      for (let i = 0; i < chunks.length; i += limit) {
        const limitedChunk = chunks.slice(i, i + limit)

        requests.push(
          PartnerTransactionRepository.reTopUpBatch({
            data: limitedChunk,
          })
        )
      }
      await Promise.all(requests)
    },

    copyForApi() {
      const { copy } = useClipboard()
      const payload = {
        data: this.items,
      }
      copy(JSON.stringify(payload))
      this.$toast.success(`Copied To Clipboard`)
    },

    batchCopyForApi() {
      const { copy } = useClipboard()

      let allString = ''

      for (let i = 0; i < this.items.length; i += 100) {
        const limitedChunk = this.items.slice(i, i + 100)
        const a = limitedChunk.map((item) => ({ tran_id: item.tranId }))
        const payload = {
          data: a,
        }

        allString += '\n\n' + JSON.stringify(payload)
      }

      copy(allString)
      this.$toast.success(`Copied To Clipboard`)
    },

    getData() {
      if (this.params.sortPayment) {
        this.params.sortPay = this.params.sortPayment.id
      }
      if (this.params.sortType) {
        this.params.type = this.params.sortType.id
      }
      this.loading = true
      PartnerTransactionRepository.listReTopUpCheck(this.params.tran_id)
        .then((response) => {
          console.log(response.data)
          let data = response.data
          this.items = [...data]
          this.total = response.data.length
          this.loading = false
          this.getReport()
        })
        .catch(() => {
          this.loading = false
        })
    },
    async reTopUpBatch() {
      if (this.items.length > 0) {
        this.reTopUpLoading = true

        const payload = this.items.map((item) => {
          return {
            tran_id: item.tranId,
          }
        })

        try {
          await this.processChunks(payload, 100)
          this.$toast.success(`Successfully Retopup`)
        } catch (error) {
          this.$toast.error(`An error occurred Error:${error.message}`)
        } finally {
          this.reTopUpLoading = false
        }
      } else {
        this.$toast.error('No Transaction To Re Topup')
      }
    },
    updateVisible(val = true) {
      this.visible = val
      this.$emit('visible', val)
    },
    collapseOpen() {
      if (this.openOnHover) this.updateVisible(true)
    },
    collapseClose() {
      if (this.openOnHover) this.updateVisible(false)
    },
  },
  setup() {
    let keyFields = [...tableFields]
    const searchFields = [...searchInputs]
    return {
      keyFields,
      searchFields,
    }
  },
  created() {
    this.collapseItemID = uuidv4()
    this.visible = this.isVisible
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
